import styled, { css } from "styled-components";
import { ReactComponent as Wordmark } from "../../assets/images/Wordmark_dark.svg";
import { breakpoint, fontSize } from "../../globalStyles/GlobalStyles";
import { ReactComponent as Menu } from "../../assets/images/Menu.svg";

export const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.bgComponent};
  height: 3.8rem;
  z-index: 1000;
  box-shadow: 0.625rem 0.625rem 0.5rem ${({ theme }) => theme.grayShadow};
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;

  @media (max-width: ${breakpoint.mediaQuerySm}) {
    padding: 0 1rem;
  }
`;

export const Logo = styled(Wordmark)`
  padding-bottom: 0.31rem;
  cursor: pointer;
  transition: cursor 0.3s;

  &:hover {
    cursor: pointer;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 11px 10px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.whiteSmoke};
  }
`;

export const UsernameAndEmail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
`;

export const Username = styled.h1`
  color: ${({ theme }) => theme.text};
  font-size: ${fontSize.text18};
  margin-top: 0.19rem;
  cursor: pointer;
`;

export const Button = styled.button`
  color: ${({ theme }) => theme.text};
  margin-left: 0.5rem;
  stroke: ${({ theme }) => theme.text};
`;

export const DropdownLogout = styled.div`
  position: absolute;
  right: 14px;
  top: 61px;
  margin-right: 10px;
  width: 300px;
  height: fit-content;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0 5px 10px;
  background: ${({ theme }) => theme.bgComponent};
  border-radius: 0 0 8px 8px;

  @media (max-width: ${breakpoint.mediaQuerySm}) {
    right: 6px;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`;
export const Line = styled.div`
  height: 0.03rem;
  background: ${({ theme }) => theme.background};
  width: 100%;
  margin-top: 1.25rem;
`;

export const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  margin: 1rem 0;
  height: 3.12rem;
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.background};

  &:hover {
    color: ${({ theme }) => theme.green};
    border: 1px solid ${({ theme }) => theme.green};
  }
`;

export const Text = styled.p`
  padding: 5px;
  color: ${({ theme }) => theme.text};
  margin-left: ${({ marginLeft }) => marginLeft};
  @media (max-width: ${breakpoint.mediaQuerySm}) {
    display: none;
  }
`;

export const LogoAndDataSync = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const sharedIconStyle = css`
  stroke: ${props => props.$issidebaractive ? props.theme.green : "#4D5058"};
  cursor: pointer;
  @media (min-width: 821px) {
    display: none;
  }
`;

export const MenuIcon = styled(Menu)`
  ${sharedIconStyle};
`;
