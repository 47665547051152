import {useSelector} from "react-redux";
import {LazyHome, routes} from "../Constants";
import {Route, RouterProvider, createBrowserRouter, createRoutesFromElements} from "react-router-dom";
import RootLayout from "../layout/RootLayout";
import ErrorBoundary from "../../components/common/errorBoundary/ErrorBoundary";

const adminRoles = ["super_admin", "manager", "resource_planner"];

const ProtectedRoutes = () => {
    const currentUser = useSelector((state) => state.auth.currentUser);
    const role = currentUser.role;
    const isAdmin = adminRoles.includes(role);

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<RootLayout/>} errorElement={<ErrorBoundary/>}>
                <Route index element={<LazyHome/>}/>
                {routes?.map((item, index) => {
                    if (isAdmin) return <Route path={item?.path} element={item?.element} key={index}/>;
                    else return !item?.protected && <Route path={item?.path} element={item?.element} key={index}/>;
                })}
            </Route>
        ),
        {basename: "/"}
    );
    return <RouterProvider router={router}/>;
};

export default ProtectedRoutes;
