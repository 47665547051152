import { Image, Wrapper } from './ButtonStyle';
import Plus from '../../../assets/images/Add.svg';

const Button = ({
	onClick,
	title,
	img,
	background,
	color,
	width,
	padding,
	isClicked,
	icon,
	blackTheme,
	className,
}) => {
	return (
		<Wrapper
			className={className}
			onClick={onClick}
			background={background}
			color={color}
			width={width}
			isClicked={isClicked}
			padding={padding}
			blackTheme={blackTheme}
		>
			{icon ? <div>{icon}</div> : img && <Image src={Plus} />}
			{title}
		</Wrapper>
	);
};

export default Button;
