import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from './store';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import LoadingSpinner from './components/common/loadingSpinner/LoadingSpinner';

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<Suspense fallback={<LoadingSpinner />}>
						<App />
					</Suspense>
				</PersistGate>
			</Provider>
			<ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
		</QueryClientProvider>
	</React.StrictMode>
);
