import { useNavigate, useRouteError } from "react-router-dom";
import {
  BackButton,
  BackButtonArrow,
  BackButtonText,
  ErrorMessageText,
  ErrorMessageTitle,
  ErrorMessageWrapper,
} from "./ErrorMessageStyle";

export default function ErrorBoundary() {
  const error = useRouteError();
  const navigate = useNavigate();

  function navigateHandler() {
    navigate(-1);
  }

  return (
    <ErrorMessageWrapper>
      <ErrorMessageTitle>Oops, something went wrong!</ErrorMessageTitle>
      <ErrorMessageText>Following error was returned:</ErrorMessageText>
      <ErrorMessageText fontStyle="italic" marginBottom="1rem">
        {error?.message}
      </ErrorMessageText>
      <ErrorMessageText>We apologize for the inconvenience.</ErrorMessageText>
      <ErrorMessageText>Please go back and try again.</ErrorMessageText>
      <BackButton onClick={navigateHandler}>
        <BackButtonArrow />
        <BackButtonText>Go Back</BackButtonText>
      </BackButton>
    </ErrorMessageWrapper>
  );
}
