import { useRef } from 'react';
import useHover from '@react-hook/hover';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Button from '../../components/common/button/Button';
import ToggleButton from '../../components/common/toggleButton/ToggleButton';
import SyncService from '../../api/services/Sync';
import {
	Wrapper,
	Container,
	LinksContainer,
	Spinner,
	SpinnersFirstDiv,
	SpinnersSecondDiv,
} from './SidebarStyle';
import { useSelector } from 'react-redux';
import { CustomSideBar } from '../../utils/CustomSideBar';
import { useMediaQuery } from '../../utils/useMediaQuery';
import { breakpoint } from '../../globalStyles/GlobalStyles';

function Sidebar({ theme, toggleTheme, isSidebarActive, collapseSidebar }) {
	const target = useRef(null);
	const hideSidebar = useMediaQuery(`(max-width:${breakpoint.mediaQuerySm})`);
	const isHovering = useHover(hideSidebar ? null : target);
	const queryClient = useQueryClient();
	const userAuthRole = useSelector(user => user.auth.currentUser?.role);
	const { mutateAsync: sync, isLoading } = useMutation({
		mutationFn: () => SyncService.updateFromProductive(),
	});
	const { data: syncDataQuery } = useQuery({
		queryKey: ['syncData'],
		queryFn: () => SyncService.getSyncData(),
	});

	const date = new Date(syncDataQuery?.time);

	const miliseconds = date.getTime();
	const offset = date.getTimezoneOffset();
	const realDate = new Date(miliseconds - offset * 60000);

	const handleSync = async () => {
		await sync(undefined, {
			onSuccess: () => {
				queryClient.invalidateQueries(['employees']);
				queryClient.invalidateQueries(['leads']);
				queryClient.invalidateQueries(['projects']);
				queryClient.invalidateQueries(['leadsPositions']);
				queryClient.invalidateQueries(['syncData']);
				queryClient.invalidateQueries(['get_equipments']);
			},
		});
	};

	return (
		<Wrapper ref={target}>
			<Container
				$ishovering={isHovering}
				$issidebaractive={isSidebarActive}
			>
				<div>
					<LinksContainer>
						<CustomSideBar
							userAuthRole={userAuthRole}
							$ishovering={isHovering}
							isHovering={isHovering}
							collapseSidebar={() =>
								hideSidebar &&
								isSidebarActive &&
								collapseSidebar()
							}
							isSidebarActive={isSidebarActive}
							hideSidebar={hideSidebar}
						/>
						{isLoading && userAuthRole === 'super_admin' && (
							<Spinner>
								<SpinnersFirstDiv />
								<SpinnersSecondDiv />
							</Spinner>
						)}
					</LinksContainer>
					{(isHovering || isSidebarActive) &&
						!isLoading &&
						userAuthRole === 'super_admin' && (
							<div
								className={`mt-[1.25rem] ml-[1.3rem] w-fit ${
									isHovering && 'ml-[1.3rem]'
								} mb-4`}
							>
								<Button title='Sync' onClick={handleSync} />
							</div>
						)}
				</div>
				{(isHovering || isSidebarActive) && (
					<div className={'bottom-div ml-6'}>
						<ToggleButton theme={theme} toggleTheme={toggleTheme} />
						{hideSidebar && (
							<span className='text-sm'>
								Last sync: {realDate.toLocaleString()}
							</span>
						)}
					</div>
				)}
			</Container>
		</Wrapper>
	);
}

export default Sidebar;
