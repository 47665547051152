import { useEffect, useState } from 'react';

export function findEmployeeProjectAssignCapacity(employee, projectName) {
	let assignedCapacity;
	employee?.projects?.find(project => {
		if (project.projects?.project_name === projectName) {
			assignedCapacity = project.assigned_capacity;
			return true;
		} else return false;
	});
	return assignedCapacity;
}

export function findEmployeeLeadAssignCapacity(employee, projectName) {
	let assignedCapacity;
	employee?.leads?.find(lead => {
		if (lead.lead_name === projectName) {
			assignedCapacity = lead.lead_capacity;
			return true;
		} else return false;
	});
	return assignedCapacity;
}

export function useDarkSide() {
	const [theme, setTheme] = useState(localStorage.theme);
	const colorTheme = theme === 'dark' ? 'light' : 'dark';
	localStorage.setItem('theme-tailwind', theme);
	useEffect(() => {
		const root = window.document.documentElement;
		root.classList.remove(colorTheme);
		root.classList.add(theme);
		localStorage.setItem('theme-tailwind', theme);
	}, [theme, colorTheme]);

	return [colorTheme, setTheme];
}
