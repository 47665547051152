import axios from "../http";

const SyncService = {
  updateFromProductive: async () => {
    const url = "/sync";
    const { data } = await axios.put(url);
    return data;
  },
  getSyncData: async () => {
    const url = "/sync";
    const { data } = await axios.get(url);
    return data;
  }
};

export default SyncService;
