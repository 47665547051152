import styled from 'styled-components';
import { fontSize } from '../../../globalStyles/GlobalStyles';

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 0.25rem;
	white-space: nowrap;
	background: ${({ background, blackTheme, theme }) =>
		blackTheme !== undefined
			? theme.bgComponent
			: background || theme.green};
	color: ${({ color, theme }) => color || theme.whiteText};
	padding: ${({ padding }) => padding || '0.5rem 1rem'};
	font-size: ${fontSize.text16};
	pointer-events: ${({ isClicked }) => isClicked && 'auto'};
	height: 3rem;

	${props =>
		props.width && {
			width: props.width || 'auto',
		}}
`;

export const Image = styled.img`
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	color: ${({ color }) => color};
	margin-right: 0.5rem;
`;

export const IconContainer = styled.span`
	margin-right: 0.5rem;
`;
