import { MainContainer, MainContent } from "./GlobalStyles";

function PageContainer({ children }) {
  return (
    <MainContainer>
      <MainContent>{children}</MainContent>
    </MainContainer>
  );
}

export default PageContainer;
