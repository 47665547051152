import React from 'react';
import { twMerge } from 'tailwind-merge';

const TypographyParagraphLarge = ({ children, className, ...props }) => {
	return (
		<p className={twMerge(className, 'text-[1rem]')} {...props}>
			{children}
		</p>
	);
};

export default TypographyParagraphLarge;
