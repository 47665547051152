import React from 'react';
import {
	HouseIcon,
	EmployeesIcon,
	PlanningIcon,
	StreamIcon,
	TechnologyIcon,
	ProjectIcon,
	HeadphonesIcon,
	LeadIcon,
	StyledNavLink,
	Text,
} from '../container/sidebar/SidebarStyle';

export const CustomSideBar = ({
	userAuthRole,
	isHovering,
	isSidebarActive,
	hideSidebar,
	collapseSidebar,
}) => {
	const sidebarList = [
		{ route: '/dashboard', name: 'Home', icon: <HouseIcon /> },
		{
			route: '/employees?filter=all&subfilter=all&sort-by=a-z',
			name: 'Employees',
			icon: <EmployeesIcon />,
		},
		...(userAuthRole === 'super_admin' ||
		userAuthRole === 'manager' ||
		userAuthRole === 'resource_planner'
			? [
					{
						route: '/resource-planning',
						name: 'Resource planning',
						icon: <PlanningIcon />,
					},
			  ]
			: []),
		...(!userAuthRole
			? [
					{
						route: '/value-stream-grid',
						name: 'Value Stream Grid',
						icon: <StreamIcon disabled />,
						disabled: true,
					},
			  ]
			: []),
		...(userAuthRole === 'super_admin'
			? [
					{
						route: '/skills',
						name: 'Skills',
						icon: <TechnologyIcon />,
					},
			  ]
			: []),
		{
			route: '/projects?status=active-projects&filter=all&subfilter=all&sort-by=a-z',
			name: 'Projects',
			icon: <ProjectIcon />,
		},
		...(userAuthRole === 'super_admin' ||
		userAuthRole === 'manager' ||
		userAuthRole === 'resource_planner'
			? [
					{
						route: '/equipment',
						name: 'Equipments',
						icon: <HeadphonesIcon />,
					},
			  ]
			: []),
		...(userAuthRole === 'super_admin' ||
		userAuthRole === 'manager' ||
		userAuthRole === 'resource_planner'
			? [
					{
						route: '/leads?filter=all&subfilter=all&sort-by=a-z',
						name: 'Leads',
						icon: <LeadIcon />,
					},
			  ]
			: []),
	];
	return (
		<div>
			{sidebarList?.map(item => {
				return (
					<StyledNavLink
						key={item?.route}
						to={item.route}
						onClick={() => collapseSidebar()}
						$issidebaractive={isSidebarActive}
						$hidesidebar={hideSidebar}
						$ishovering={isHovering}
						disabled={item?.disabled}
					>
						{item.icon}
						{(isHovering || isSidebarActive) && (
							<Text $issidebaractive={isSidebarActive}>
								{item.name}
							</Text>
						)}
					</StyledNavLink>
				);
			})}
		</div>
	);
};
