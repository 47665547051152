import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, setAuthUser } from '../../features/auth/AuthSlice';
import { ReactComponent as ZendevLogo } from '../../assets/images/ZenDevLogin.svg';
import { ReactComponent as GoogleLogo } from '../../assets/images/GoogleLogo.svg';

import {
	Container,
	ImageContainer,
	InnerContainer,
	LoginButton,
	LoginWrapper,
} from './LoginStyle';
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { showToastMessage } from '../../components/common/toast/Toast';
import TypographyH1 from '../../components/typography/h1';
import TypographyParagraphLarge from '../../components/typography/paragraphLarge';

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
	const queryParams = new URLSearchParams(location.search);
	const inactive_user = queryParams.get('inactive_user');

	const token = queryParams.get('token');
	useEffect(() => {
		if (inactive_user) {
			showToastMessage(
				'Your account is inactive, please try another account',
				'error'
			);
		}
	}, [inactive_user]);

	useEffect(() => {
		if (token) {
			const decodedUser = jwtDecode(token);
			const user = {
				name: decodedUser.user_info?.first_name || 'first name',
				last_name: decodedUser.user_info?.last_name || 'last name',
				imgUrl: decodedUser.user_info?.profile_picture,
				email: decodedUser.user_info?.email,
				role: decodedUser.user_info?.role,
			};
			dispatch(login(token));
			dispatch(setAuthUser(user));
			navigate('/dashboard');
		}
	}, [dispatch, navigate, token]);

	useEffect(() => {
		if (isLoggedIn) {
			navigate('/dashboard');
		}
	}, [isLoggedIn, navigate]);

	return (
		<LoginWrapper>
			<Container>
				<InnerContainer>
					<TypographyH1 className='font-semibold text-primary'>
						Sign in
					</TypographyH1>
					<TypographyParagraphLarge className='text-tertiary'>
						Welcome back! Please sign in with your ZenDev account.
					</TypographyParagraphLarge>
					<LoginButton href='/api/auth/google'>
						<GoogleLogo className='mr-2 ml-2' />
						<TypographyParagraphLarge className='text-secondary font-semibold'>
							Sign in with Google
						</TypographyParagraphLarge>
					</LoginButton>
				</InnerContainer>
			</Container>
			<ImageContainer>
				<ZendevLogo />
			</ImageContainer>
		</LoginWrapper>
	);
};

export default Login;
