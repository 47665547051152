import format from 'date-fns/format';

const FormatUtils = {
	formatDate(date, typeFormat) {
		return format(new Date(date), typeFormat);
	},

	formatUrlLeads(filter, subfilter, text, sortFilter) {
		return `/leads?status=${filter}&number_of_positions=${subfilter}&sort_by=${sortFilter}&search=${text}`;
	},

	formatUrlEmployees(filter, subfilter, sortFilter, skillsFilter, text) {
		return `/employees?filter=${filter}&subfilter=${subfilter}${
			skillsFilter ? `&skills=${skillsFilter}` : ''
		}&sortby=${sortFilter}&search=${text}`;
	},
	formatUrlEmployee(id) {
		return `/employees/${id}`;
	},

	formatUrlProjects(status, filter, subfilter, sortFilter, text) {
		return `/projects?status=${status}&filter=${filter}&subfilter=${subfilter}&sortby=${sortFilter}&search=${text}`;
	},

	formatUrlTechnologies(sortFilter, text) {
		return `/skills?filter=null&subfilter=null&sort_by=${sortFilter}&search=${text}`;
	},

	formatUrlPositions(id, sortFilter, sortDropdown, text) {
		return text
			? `/positions?search=${text}`
			: id
			  ? `/positions/employee/${id}`
			  : sortDropdown
			    ? `/positions${sortFilter ? `?sortby=${sortFilter}` : ''}`
			    : `/positions/`;
	},

	formatUrlAllLeads() {
		return `/leads/`;
	},
	formatUrlSortedEmployees(id, sortFilter, sortDropdown, text) {
		return text
			? `/employees?search=${text}`
			: id
			  ? `/employees/position/${id}`
			  : sortDropdown
			    ? `/employees?sortby=${sortFilter}&search=${text}`
			    : `/employees/`;
	},
	formatUrlEquipments(text, sort, start, end) {
		return text
			? `/equipment?searchQuery=${text}`
			: sort
			  ? `/equipment?sortBy=${sort}`
			  : start || end
			    ? `/equipment?startDate=${start}&endDate=${end}`
			    : `/equipment`;
	},

	formatUrlDownloadEquipments(text, sort, start, end) {
		const queryParams = [];

		if (text) queryParams.push(`searchQuery=${text}`);
		if (sort) queryParams.push(`sortBy=${sort}`);
		if (start) queryParams.push(`startDate=${start}`);
		if (end) queryParams.push(`endDate=${end}`);

		const queryString =
			queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

		return `/equipment/downloadFile${queryString}`;
	},
};

export default FormatUtils;
