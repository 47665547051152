import { NavLink } from 'react-router-dom';
import styled, { keyframes, css } from 'styled-components';
import { ReactComponent as Home } from '../../assets/images/Home.svg';
import { ReactComponent as Employees } from '../../assets/images/Employees.svg';
import { ReactComponent as Resourceplanning } from '../../assets/images/Resourceplanning.svg';
import { ReactComponent as VSG } from '../../assets/images/VSG.svg';
import { ReactComponent as Tech } from '../../assets/images/Tech.svg';
import { ReactComponent as Projects } from '../../assets/images/Projects.svg';
import { ReactComponent as Equipment } from '../../assets/images/Equipment.svg';
import { ReactComponent as Leads } from '../../assets/images/Leads.svg';
import {
	breakpoint,
	fontSize,
	scrollBarStyle,
} from '../../globalStyles/GlobalStyles';

export const Wrapper = styled.div``;

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const Text = styled.p`
	padding-left: 0.625rem;
	opacity: ${({ $issidebaractive }) => ($issidebaractive ? 1 : 0)};
	font-size: ${({ fontSize }) => fontSize};
	margin-top: ${({ marginTop }) => marginTop};
`;

export const Container = styled.div`
	width: ${({ $ishovering }) => ($ishovering ? '14rem' : '4.37rem')};
	background: ${({ theme }) => theme.bgComponent};
	height: 100%;
	position: fixed;
	z-index: 900;
	padding-top: 5rem;
	padding-bottom: 2.18rem;
	transition: 0.1s ease-out;
	box-shadow: 0.625rem 0.625rem 0.5rem ${({ theme }) => theme.grayShadow};
	font-size: ${fontSize.text16};
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	${scrollBarStyle};

	@media (max-width: ${breakpoint.mediaQuerySm}) {
		width: ${({ $issidebaractive }) => ($issidebaractive ? '50%' : 0)};
	}

	@media (max-width: ${breakpoint.mediaQueryXSm}) {
		width: ${({ $issidebaractive }) => ($issidebaractive ? '70%' : 0)};
	}

	&:hover ${Text} {
		animation: ${({ $issidebaractive }) =>
			!$issidebaractive &&
			css`
				${FadeIn} 0.1s ease-in 0.15s forwards
			`};
	}
`;

export const LinksContainer = styled.div``;

const sharedIconStyle = css`
	fill: ${({ disabled, theme }) =>
		disabled ? theme.gray : theme.inactiveIcon};
`;

export const HouseIcon = styled(Home)`
	${sharedIconStyle}
`;

export const EmployeesIcon = styled(Employees)`
	${sharedIconStyle}
`;

export const PlanningIcon = styled(Resourceplanning)`
	${sharedIconStyle}
`;

export const StreamIcon = styled(VSG)`
	${sharedIconStyle}
`;

export const TechnologyIcon = styled(Tech)`
	${sharedIconStyle}
`;

export const ProjectIcon = styled(Projects)`
	${sharedIconStyle}
`;

export const HeadphonesIcon = styled(Equipment)`
	${sharedIconStyle}
`;

export const LeadIcon = styled(Leads)`
	${sharedIconStyle}
`;

export const StyledNavLink = styled(NavLink)`
	display: flex;
	align-items: center;
	padding: 1.2rem 1.3rem;
	margin: 0.8rem 0;
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
	height: 2.5rem;
	color: ${({ disabled, theme }) => (disabled ? theme.gray : theme.text)};

	@media (max-width: ${breakpoint.mediaQuerySm}) {
		margin: 1.5rem 0;
	}

	&:hover {
		color: ${({ theme }) => theme.green};

		&,
		${HouseIcon}, &,
		${EmployeesIcon}, &,
		${PlanningIcon}, &,
		${StreamIcon}, &,
		${TechnologyIcon}, &,
		${ProjectIcon}, &,
		${HeadphonesIcon}, &,
		${LeadIcon} {
			fill: ${({ theme }) => theme.green};
		}
	}

	&.active {
		position: relative;
		display: flex;

		&:after {
			content: '';
			display: ${({ $issidebaractive, $hidesidebar }) =>
				!$hidesidebar
					? 'unset'
					: $issidebaractive && $hidesidebar
					  ? 'inline'
					  : 'none'};
			position: absolute;
			bottom: 0.2rem;
			left: 1.45rem;
			width: 1.1rem;
			height: 0.25rem;
			background-color: ${({ theme }) => theme.green};
			border-radius: 187.5rem;
		}

		&,
		${HouseIcon}, &,
		${EmployeesIcon}, &,
		${PlanningIcon}, &,
		${StreamIcon}, &,
		${TechnologyIcon}, &,
		${ProjectIcon}, &,
		${HeadphonesIcon}, &,
		${LeadIcon} {
			fill: ${({ theme }) => theme.activeIcon};
		}

		&:hover {
			&,
			${HouseIcon}, &,
			${EmployeesIcon}, &,
			${PlanningIcon}, &,
			${StreamIcon}, &,
			${TechnologyIcon}, &,
			${ProjectIcon}, &,
			${HeadphonesIcon}, &,
			${LeadIcon} {
				fill: ${({ theme }) => theme.green};
			}
		}
	}
`;

const SpinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
    border-width: 10px;
  }

  50% {
    transform: rotate(180deg);
    border-width: 1px;
  }
  100% {
    transform: rotate(360deg);
    border-width: 10px;
  }
`;

const SecondSpinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
    border-width: 1px;
  }

  50% {
    transform: rotate(180deg);
    border-width: 10px;
  }
  100% {
    transform: rotate(360deg);
    border-width: 1px;
  }
`;

export const Spinner = styled.div`
	width: 50px;
	height: 50px;
	margin-left: 10px;
	position: relative;
`;

export const SpinnersFirstDiv = styled.div`
	box-sizing: border-box;
	position: absolute;
	width: 100%;
	height: 100%;
	border: 10px solid transparent;
	border-top-color: ${({ theme }) => theme.green};
	border-radius: 50%;
	animation: ${SpinnerAnimation} 1.2s linear infinite;
`;
export const SpinnersSecondDiv = styled.div`
	box-sizing: border-box;
	position: absolute;
	width: 100%;
	height: 100%;
	border: 10px solid transparent;
	border-bottom-color: ${({ theme }) => theme.green};
	animation: ${SecondSpinnerAnimation} 1.2s linear infinite;
	border-radius: 50%;
`;
