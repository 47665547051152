import styled from "styled-components";

export const SwitchLabel = styled.div`
  height: 2rem;
  width: 5.31rem;
  border-radius: 1.87rem;
  background: ${({ theme }) => theme.background};

  &:before {
    content: "";
    position: absolute;
    left: 2.05rem;
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0.31rem;
    background-color: ${({ theme }) => theme.bgComponent};
    border-radius: 187.5rem;
    transition: 1s;
    transform: ${(props) => (props.themeSelected === "dark" ? "translateX(3rem)" : "translateX(-0.2rem)")};
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
