import axios from "../http.js"

const AuthService = {
    logout: async () => {
        const url = "auth/logout"
        const { data } = await axios.get(url);
        return data;
    }
}

export default AuthService;