export const lightTheme = {
	background: '#efefef',
	white: '#fefefe',
	bgComponent: '#fff',
	gray: '#f3f3f3',
	grayShadow: '#e8e8e8',
	boxShadow: '#00000029',
	grayBorder: '#DBDEE1',
	blue: '#2CA0F8',
	green: '#33cc99',
	red: '#FF2929',
	black40: 'rgba(0, 0, 0, 0.4)',
	text: '#4d5058',
	subtitleCardColor: '#7C7C7C',
	inactiveIcon: '#C6C6C6',
	activeIcon: '#6a6c72',
	activeLink: '#f9f9f9',
	activeNestedLink: '#f1f1f1',
	filterLine: '#DBDCDE',
	pageLine: '#707070',
	pinkCard: '#FFE9E9',
	yellowCard: '#FFF4E2',
	blueCard: '#DAF4FF',
	gray70: 'rgba(148, 148, 148, 0.7)',
	grayModal: '#909090',
	orange: '#DC9C34',
	yellow: '#FFBB49',
	arrowDown: '#949494',
	whiteText: '#FFF',
	orangeDate: '#FF7D3D',
	darkGray: '#94969B',
	whiteSmoke: '#F4F4F4',
};

export const darkTheme = {
	background: '#4D5058',
	white: '#4D5058',
	bgComponent: '#373E48',
	gray: '#C6C6C6',
	grayShadow: '#00000014',
	boxShadow: '#00000029',
	grayBorder: '#DBDEE1',
	blue: '#2CA0F8',
	green: '#33cc99',
	red: '#FF2929',
	black40: 'rgba(0, 0, 0, 0.4)',
	text: '#F9F2ED',
	sameTextColor: '#4d5058',
	subtitleCardColor: '#7C7C7C',
	inactiveIcon: '#6a6c72',
	activeIcon: '#C6C6C6',
	activeLink: '#4D5058',
	activeNestedLink: '#4D5058',
	filterLine: '#f9f9f9',
	pageLine: '#373E48',
	pinkCard: '#ffbdac',
	yellowCard: '#ffe9c4',
	blueCard: '#DAF4FF',
	gray70: 'rgba(148, 148, 148, 0.7)',
	grayModal: '#909090',
	orange: '#DC9C34',
	yellow: '#FFBB49',
	arrowDown: '#949494',
	whiteText: '#FFF',
	darkGray: '#94969B',
	whiteSmoke: '#535962',
};
