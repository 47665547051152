import axios from 'axios';
import { logout } from '../features/auth/AuthSlice';
import { store } from '../store';
import { showToastMessage } from '../components/common/toast/Toast';

let isTokenExpired = false;

const instance = axios.create({
	baseURL: '/api/',
	withCredentials: true,
});

// Request interceptor for API calls
instance.interceptors.request.use(
	config => {
		const token = store.getState().auth.token;

		// Set common headers
		config.headers = {
			Authorization: `Bearer ${token}`,
			Accept: 'application/json',
			'Access-Control-Allow-Origin': '*',
			// Default to 'application/json', but allow individual requests to override
			'Content-Type':
				config.headers['Content-Type'] || 'application/json',
		};
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);
instance.interceptors.response.use(
	response => response,
	error => {
		const status = error.response ? error.response.status : null;
		if (status === 401 || status === 403) {
			if (!isTokenExpired) {
				isTokenExpired = true;
				store.dispatch(logout());
				showToastMessage(
					'Your session has expired, please log in again!',
					'error'
				);
			}
		}
		return Promise.reject(error);
	}
);

export default instance;
