import styled from "styled-components";
import { breakpoint } from "../../../globalStyles/GlobalStyles";

export const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
  color: #333;
  font-family: "Helvetica Neue", sans-serif;

  @media (max-width: ${breakpoint.mediaQueryMd}) {
  }
`;

export const ErrorMessageTitle = styled.h1`
  margin-top: 2rem;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  @media (max-width: ${breakpoint.mediaQueryMd}) {
    font-size: 2rem;
  }
`;
export const ErrorMessageText = styled.p`
  font-size: 1.5rem;
  max-width: 600px;
  text-align: center;
  font-style: ${(props) => props.fontStyle};
  margin-bottom: ${(props) => props.marginBottom};
  @media (max-width: ${breakpoint.mediaQueryMd}) {
    font-size: 1.2rem;
  }
`;

export const BackButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 0.5rem;
  padding: 0.85rem 1.25rem;
  height: fit-content;
  background-color: hsl(160, 60%, 50%);
  color: #fff;
  cursor: pointer;
  align-self: center;
  margin-left: 10px;
  margin-top: 1rem;
  transition: background-color 0.15s ease 0.03s;

  @media (max-width: ${breakpoint.mediaQueryMd}) {
    padding: 0.65rem 1rem;
  }

  &:hover {
    background-color: hsl(160, 60%, 40%);
  }
`;
export const BackButtonArrow = styled.div`
  border: solid white;
  border-width: 0 3px 3px 0;
  padding: 3.5px;
  position: relative;
  bottom: 0px;
  transform: rotate(45deg);
  -webkit-transform: rotate(135deg);

  @media (max-width: ${breakpoint.mediaQueryMd}) {
    border-width: 0 2.5px 2.5px 0;
  }
`;

export const BackButtonText = styled.p`
  margin: 0 5px;
`;
