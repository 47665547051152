import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    token: '',
    currentUser: {name: "", last_name: "", email: "", role: ""},
};

const AuthSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, {payload}) => {
            state.isLoggedIn = true;
        },
        logout: (state) => {
            state.token = null;
            state.isLoggedIn = false;
        },
        setAuthUser: (state, {payload}) => {
            state.isLoggedIn = true;
            state.currentUser = payload;
        },
    },
});

export const {login, logout, setAuthUser} = AuthSlice.actions;
export default AuthSlice.reducer;
