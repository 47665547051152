import React from 'react';
import { twMerge } from 'tailwind-merge';

const TypographyH1 = ({ children, className, ...props }) => {
	return (
		<h1
			className={twMerge(
				className,
				'text-[2.25rem] mobile:text-[2rem]'
			)}
			{...props}
		>
			{children}
		</h1>
	);
};

export default TypographyH1;
