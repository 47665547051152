import { lazy } from 'react';
import Jira from '../assets/images/Jira.svg';
import Lattice from '../assets/images/lattice.svg';
import BitBucket from '../assets/images/bucket.svg';
import Productive from '../assets/images/Productive.svg';
import Avatar from '../assets/images/ProfilePhoto.png';
import { store } from '../store';

export const LazyHome = lazy(() => import('../pages/home/Home'));
const LazyEmployees = lazy(() => import('../pages/employees/Employees'));
const LazyEquipment = lazy(() => import('../pages/equipment/Equipment'));
const LazyResourcePlanning = lazy(
	() => import('../pages/resourcePlanning/ResourcePlanning')
);
const LazyValueStreamGrid = lazy(
	() => import('../pages/valueStreamGrid/ValueStreamGrid')
);
const LazyProjects = lazy(() => import('../pages/projects/Projects'));
const LazyTechnologies = lazy(
	() => import('../pages/technologies/Technologies')
);
const LazyLeads = lazy(() => import('../pages/leads/Leads'));
const LazyLogin = lazy(() => import('../pages/login/Login'));

export const routes = [
	{ path: '/', element: <LazyLogin /> },
	{ path: '/dashboard', element: <LazyHome /> },
	{ path: '/employees/*', element: <LazyEmployees /> },
	{
		path: '/resource-planning',
		element: <LazyResourcePlanning />,
		protected: true,
	},
	{
		path: '/value-stream-grid',
		element: <LazyValueStreamGrid />,
		protected: true,
	},
	{ path: '/skills', element: <LazyTechnologies />, protected: true },
	{ path: '/projects', element: <LazyProjects /> },
	{ path: '/equipment', element: <LazyEquipment />, protected: true },
	{ path: '/leads/*', element: <LazyLeads />, protected: true },
	{ path: '/*', element: <LazyHome /> },
];

export const usefulLinks = [
	{ icon: Lattice, link: 'https://zendev.latticehq.com/' },
	{
		icon: Productive,
		link: 'https://app.productive.io/3343-zendev-ab/dashboards/56797 ',
	},
	{ icon: BitBucket, link: 'https://bitbucket.org/dashboard/repositories' },
	{ icon: Jira, link: 'https://zendev.atlassian.net/jira/dashboards/' },
];

export const departmentsList = [
	{ subtitle: 'All roles', route: 'all' },
	{ subtitle: 'Business Manager', route: 'business-manager' },
	{ subtitle: 'Project Owner', route: 'project-owner' },
	{ subtitle: 'UI/UX', route: 'ui-ux' },
	{ subtitle: 'Project Manager', route: 'project-manager' },
	{ subtitle: 'Developer', route: 'developer' },
	{ subtitle: 'QA', route: 'qa' },
	{ subtitle: 'Marketing', route: 'marketing' },
	{ subtitle: 'DevOps', route: 'devops' },
	{ subtitle: 'Operations', route: 'operations' },
	{ subtitle: 'Administration', route: 'administration' },
	{ subtitle: 'Sales', route: 'sales' },
	{ subtitle: 'Management', route: 'management' },
];

export const availabilityList = [
	{ subtitle: 'All', route: 'all' },
	{ subtitle: 'Available', route: 'available' },
	{ subtitle: 'Ongoing', route: 'ongoing' },
	{ subtitle: 'Ending soon', route: 'ending-soon' },
];

export const leadsList = [
	{ subtitle: 'All leads', route: 'all' },
	{ subtitle: 'Hot leads', route: 'hot' },
	{ subtitle: 'Mild leads', route: 'mild' },
	{ subtitle: 'Cold leads', route: 'cold' },
];

export const positionsList = [
	{ subtitle: 'All', route: 'all' },
	{ subtitle: '1-3 persons', route: '1-3-persons' },
	{ subtitle: '3-5 persons', route: '3-5-persons' },
	{ subtitle: '5+ persons', route: '5-and-more' },
];

export const activityStatusList = [
	{ subtitle: 'Active projects', route: 'active-projects' },
	{ subtitle: 'Archived projects', route: 'archived-projects' },
	{ subtitle: 'Hidden projects', route: 'hidden-projects' },
];

export const projectsList = [
	{ subtitle: 'All projects', route: 'all' },
	{ subtitle: 'Internal projects', route: 'internal-projects' },
	{ subtitle: 'External projects', route: 'external-projects' },
];

export const durationList = [
	{ subtitle: 'All', route: 'all' },
	{ subtitle: 'Finishing soon', route: 'finishing-soon' },
	{ subtitle: 'Ongoing', route: 'ongoing' },
	{ subtitle: 'Finished', route: 'finished' },
];

const userAuthRole = store.getState().auth.currentUser?.role;

export const menuModal = [
	{ name: 'Info', query: 'info', enabled: true },
	{ name: 'Projects', query: 'projects', enabled: true },
	{ name: 'Equipment', query: 'equipment', enabled: false },
	...(userAuthRole !== 'employee'
		? [{ name: 'Logs', query: 'logs', enabled: true }]
		: [{}]),
];

export const logs = [
	{
		user: 'Farik Vrce ',
		action: 'removed skill from ',
		anotherUser: 'Hadis Durmis',
		date: '30/08/2022',
	},
	{
		user: 'Farik Vrce ',
		action: 'removed skill from ',
		anotherUser: 'Hadis Durmis',
		date: '24/08/2022',
	},
	{
		user: 'Farik Vrce ',
		action: 'added skill to ',
		anotherUser: 'Hadis Durmis',
		date: '30/07/2022',
	},
	{
		user: 'Farik Vrce ',
		action: 'removed skill from ',
		anotherUser: 'Hadis Durmis',
		date: '24/08/2022',
	},
	{
		user: 'Farik Vrce ',
		action: 'added skill to ',
		anotherUser: 'Hadis Durmis',
		date: '30/07/2022',
	},
	{
		user: 'Farik Vrce ',
		action: 'removed skill from ',
		anotherUser: 'Hadis Durmis',
		date: '24/08/2022',
	},
	{
		user: 'Farik Vrce ',
		action: 'added skill to ',
		anotherUser: 'Hadis Durmis',
		date: '30/07/2022',
	},
	{
		user: 'Farik Vrce ',
		action: 'removed skill from ',
		anotherUser: 'Hadis Durmis',
		date: '24/08/2022',
	},
	{
		user: 'Farik Vrce ',
		action: 'added skill to ',
		anotherUser: 'Hadis Durmis',
		date: '30/07/2022',
	},
	{
		user: 'Farik Vrce ',
		action: 'removed skill from ',
		anotherUser: 'Hadis Durmis',
		date: '24/08/2022',
	},
	{
		user: 'Farik Vrce ',
		action: 'added skill to ',
		anotherUser: 'Hadis Durmis',
		date: '30/07/2022',
	},
	{
		user: 'Farik Vrce ',
		action: 'added skill to ',
		anotherUser: 'Hadis Durmis',
		date: '24/06/2022',
	},
];

export const finishingProjects = [
	{ name: 'Fantasy Football', date: '30/08/2022' },
	{ name: 'Srenity', date: '30/08/2022' },
	{ name: 'Isolve (Accurasee)', date: '30/08/2022' },
	{ name: 'Tingstad Ecommerce', date: '30/08/2022' },
	{ name: 'FoodNotify', date: '30/08/2022' },
	{ name: 'Fantasy Football', date: '30/08/2022' },
	{ name: 'Srenity', date: '30/08/2022' },
	{ name: 'Isolve (Accurasee)', date: '30/08/2022' },
	{ name: 'Tingstad Ecommerce', date: '30/08/2022' },
	{ name: 'FoodNotify', date: '30/08/2022' },
	{ name: 'Fantasy Football', date: '30/08/2022' },
	{ name: 'Srenity', date: '30/08/2022' },
	{ name: 'Isolve (Accurasee)', date: '30/08/2022' },
	{ name: 'Tingstad Ecommerce', date: '30/08/2022' },
	{ name: 'FoodNotify', date: '30/08/2022' },
];

export const employeesWithEndingProjects = [
	{
		icon: Avatar,
		name: 'Amir Zahirović',
		position: 'DevOps',
		date: '30/08/2022',
	},
	{
		icon: Avatar,
		name: 'Hadis Durmiš',
		position: 'Backend dev',
		date: '30/08/2022',
	},
	{
		icon: Avatar,
		name: 'Amer Bilić',
		position: 'Frontend dev',
		date: '30/08/2022',
	},
	{
		icon: Avatar,
		name: 'Enes Ajanović',
		position: 'Frontend dev',
		date: '30/08/2022',
	},
	{
		icon: Avatar,
		name: 'Ilhana Džeko',
		position: 'Frontend dev',
		date: '30/08/2022',
	},
	{
		icon: Avatar,
		name: 'Amir Zahirović',
		position: 'DevOps',
		date: '30/08/2022',
	},
	{
		icon: Avatar,
		name: 'Hadis Durmiš',
		position: 'Backend dev',
		date: '30/08/2022',
	},
	{
		icon: Avatar,
		name: 'Amer Bilić',
		position: 'Frontend dev',
		date: '30/08/2022',
	},
	{
		icon: Avatar,
		name: 'Enes Ajanović',
		position: 'Frontend dev',
		date: '30/08/2022',
	},
	{
		icon: Avatar,
		name: 'Ilhana Džeko',
		position: 'Frontend dev',
		date: '30/08/2022',
	},
	{
		icon: Avatar,
		name: 'Amir Zahirović',
		position: 'DevOps',
		date: '30/08/2022',
	},
	{
		icon: Avatar,
		name: 'Hadis Durmiš',
		position: 'Backend dev',
		date: '30/08/2022',
	},
	{
		icon: Avatar,
		name: 'Amer Bilić',
		position: 'Frontend dev',
		date: '30/08/2022',
	},
	{
		icon: Avatar,
		name: 'Enes Ajanović',
		position: 'Frontend dev',
		date: '30/08/2022',
	},
	{
		icon: Avatar,
		name: 'Ilhana Džeko',
		position: 'Frontend dev',
		date: '30/08/2022',
	},
];

export const projectTitles = ['Projects', 'Timeframe', 'Position & Tech'];
export const projectTitlesTablet = ['Projects', 'Timeframe'];

export const dropDownListModal = [
	{ name: 'High', value: 'high' },
	{ name: 'Mid', value: 'mid' },
	{ name: 'Low', value: 'low' },
];

export const dropDownListPage = [
	{ name: 'Sort by', value: 'not-sort' },
	{ name: 'A - Z', value: 'a-z' },
	{ name: 'Z - A', value: 'z-a' },
];

export const dropDownListAvailability = [
	{ name: 'Availability (%)', value: 'not-sort' },
	{ name: '1 - 9', value: '1-9' },
	{ name: '9 - 1', value: '9-1' },
];

export const dropDownListPageBox = [
	{ subtitle: 'A - Z', route: 'a-z' },
	{ subtitle: 'Z - A', route: 'z-a' },
];

export const dropDownListAvailabilityBox = [
	{ subtitle: '1 - 9', route: '1-9' },
	{ subtitle: '9 - 1', route: '9-1' },
];
