import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ArrowDown } from "../../assets/images/ArrowDown.svg";
import { breakpoint, Image } from "../../globalStyles/GlobalStyles";
import {
  Button,
  Container,
  DropdownLogout,
  Line,
  Logo,
  LogoAndDataSync,
  LogoutButton,
  MenuIcon,
  Text,
  UserInfo,
  Username,
  UsernameAndEmail,
  Wrapper,
} from "./NavbarStyle";
import { logout } from "../../features/auth/AuthSlice";
import { useNavigate } from "react-router-dom";
import useHover from "@react-hook/hover";
import SyncService from "../../api/services/Sync";
import { useQuery } from "@tanstack/react-query";
import EmployeesService from "../../api/services/Employees";
import AuthService from "../../api/services/Auth";
import { useMediaQuery } from "../../utils/useMediaQuery";

function Navbar({ isSidebarActive, toggleSidebar }) {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [showLogout, setShowLogout] = useState(false);
  const dispatch = useDispatch();
  const target = useRef(null);
  const isHovering = useHover(target);
  const navigate = useNavigate();

  const logoutUser = () => {
    AuthService.logout();
    dispatch(logout());
    navigate("/");
  };

  useEffect(() => {
    const closeLogout = (e) => {
      if (target.current && !target.current.contains(e.target)) {
        setShowLogout(false);
      }
    };
    document.addEventListener("click", closeLogout);
    return () => document.removeEventListener("click", closeLogout);
  }, []);

  const { data: employeeImageQuery } = useQuery({
    queryKey: ["userImage"],
    queryFn: () => EmployeesService.getEmployeeImage()
  });

  const { data: syncDataQuery } = useQuery({
    queryKey: ["syncData"],
    queryFn: () => SyncService.getSyncData()
  });

  const date = new Date(syncDataQuery?.time);
  const miliseconds = date.getTime();
  const offset = date.getTimezoneOffset();
  const realDate = new Date(miliseconds - offset * 60000);
  const isMobile = useMediaQuery(`(max-width:${breakpoint.mediaQueryXSm})`);

  return (
    <Wrapper>
      <LogoAndDataSync>
        <MenuIcon
            $issidebaractive={isSidebarActive}
          onClick={() => toggleSidebar()}
        />
        <Logo onClick={() => navigate("/dashboard")} />
        <Text fontSize="14px" marginLeft="30px" alert>
          Last Sync:
        </Text>
        <Text fontSize="14px">{realDate.toLocaleString()}</Text>
      </LogoAndDataSync>
      <Container
        ref={target}
        showLogout={showLogout}
        onClick={() => setShowLogout(!showLogout)}
      >
        <Image
          src={employeeImageQuery?.profile_picture}
          width="2.5rem"
          height="2.5rem"
          borderRadius="50%"
          referrerpolicy="no-referrer"
        />
        {!isMobile && (
          <Username className="ml-4">
            {currentUser?.name + " " + currentUser?.last_name}
          </Username>
        )}
        <Button>
          <ArrowDown
            className="ml-2"
            style={{
              transition: "all 0.5s ease",
              transform: `rotate(${showLogout ? "0.5turn" : 0})`,
            }}
          />
        </Button>
      </Container>
      {showLogout && (
        <DropdownLogout>
          <UserInfo>
            <Image
              src={employeeImageQuery?.profile_picture}
              width="6rem"
              height="6rem"
              borderRadius="50%"
              referrerpolicy="no-referrer"
            />
            <UsernameAndEmail>
              <Username>
                {currentUser?.name + " " + currentUser?.last_name}
              </Username>
              <Username>{currentUser?.email}</Username>
            </UsernameAndEmail>
          </UserInfo>
          <Line />
          <LogoutButton
            className={({ isActive }) => (isActive ? "active" : "none")}
            $ishovering={isHovering}
            onClick={logoutUser}
          >
            Sign out
          </LogoutButton>
        </DropdownLogout>
      )}
    </Wrapper>
  );
}

export default Navbar;
