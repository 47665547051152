import {configureStore} from "@reduxjs/toolkit";
import AuthReducer from "./features/auth/AuthSlice";
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whitelist: ["currentUser", "isLoggedIn"], // Specify the keys that should be persisted
};

const persistedReducer = persistReducer(persistConfig, AuthReducer);

export const store = configureStore({
    reducer: {auth: persistedReducer},
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});
