import {useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {setAuthUser} from "./features/auth/AuthSlice";
import { jwtDecode } from "jwt-decode";
import ProtectedRoutes from "./utils/routes/ProtectedRoutes";

function App() {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);

    useEffect(() => {
        if (token) {
            const decodedUser = jwtDecode(token);
            const user = {
                name: decodedUser.user_info?.first_name || "first name",
                last_name: decodedUser.user_info?.last_name || "last name",
                imgUrl: decodedUser.user_info?.profile_picture,
                email: decodedUser.user_info?.email,
                role: decodedUser.user_info?.role || "employee",
            };
            dispatch(setAuthUser(user));
        }
    }, [token, dispatch]);

    return <ProtectedRoutes/>;
}

export default App;
