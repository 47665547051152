import { useState } from 'react';
import { func, string } from 'prop-types';
import { ReactComponent as Moon } from '../../../assets/images/Moon.svg';
import { ReactComponent as Sun } from '../../../assets/images/Sun.svg';
import { SwitchLabel, IconsContainer } from './ToggleButtonStyle';
import { useDarkSide } from '../../../features/helpers/helper-functions';

function ToggleButton({ theme, toggleTheme }) {
	const [isClicked, setIsClicked] = useState(false);

	const [colorTheme, setTheme] = useDarkSide();
	// eslint-disable-next-line
	const [darkSide, setDarkSide] = useState(
		colorTheme === 'light' ? true : false
	);

	const toggleDarkMode = checked => {
		setTheme(colorTheme);
		setDarkSide(checked);
	};

	return (
		<SwitchLabel
			onClick={() => {
				setIsClicked(!isClicked);
				toggleTheme();
				toggleDarkMode();
			}}
			isClicked={isClicked}
			themeSelected={theme}
		>
			<IconsContainer>
				<Sun className='m-2 z-10' />
				<Moon className='m-2 z-10' />
			</IconsContainer>
		</SwitchLabel>
	);
}

ToggleButton.propTypes = {
	theme: string.isRequired,
	toggleTheme: func.isRequired,
};

export default ToggleButton;
