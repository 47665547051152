import { createGlobalStyle } from 'styled-components';
import styled, { css } from 'styled-components';

export const fontSize = {
	text40: '2.5rem',
	text33: '2.063rem',
	text25: '1.56rem',
	text24: '1.5rem',
	text21: '1.3rem',
	text20: '1.25rem',
	text18: '1.12rem',
	text17: '1.06rem',
	text16: '1rem',
	text15: '0.93rem',
	text13: '0.8rem',
	text12: '0.75rem',
};

export const breakpoint = {
	mediaQueryXSm: '37.5rem',
	mediaQuerySm: '51.25rem',
	mediaQueryMd: '1550px',
	mediaQueryXL: '76.5rem',
	mediaQueryLg: '96rem',
	mediaQueryXXL: '91.25rem',
};

export const colors = {
	grayBorder: '#DBDEE1',
	green: '#33cc99',
	lightGreen: '#33CC99',
	blue: '#2CA0F8',
	red: '#FF2929',
	orange: '#DC9C34',
	gray: '#4d5058',
	gray70: 'rgba(148, 148, 148, 0.7)',
	yellow: '#FFBB49',
	pinkCard: '#FFE9E9',
	white: '#FFF',
	lightGray: '#C6C6C6',
	grayModal: '#909090',
	lightGrayModal: '#94969B',
	grayShadow: '#00000029',
	brown: '#FF8000',
	black: '#000000',
	graySecondary: '#F3F3F3',
};

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.background};
    font-family: "Segoe UI", sans-serif !important;
    height: 100vh;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      background: transparent;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.gray70};
      border-radius: 20px;

      &:hover {
        background: ${({ theme }) => theme.grayModal};
      }
    }


    @media (max-width: ${breakpoint.mediaQuerySm}) {
      overflow: scroll
    }
  }
`;

export const MainContainer = styled.div`
	width: 100%;
	display: flex;
`;

export const MainContent = styled.div`
	width: 100%;
	height: calc(95vh - 70px);
	margin-left: 5.5rem;
	margin-top: 5rem;
	margin-right: 1rem;

	@media (max-width: ${breakpoint.mediaQuerySm}) {
		margin-left: 1rem;
	}

	@media (max-width: ${breakpoint.mediaQueryXSm}) {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}
`;

export const Image = styled.img`
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	border-radius: ${({ borderRadius }) => borderRadius};
	margin-left: ${({ marginLeft }) => marginLeft};
	cursor: pointer;
`;

export const Title = styled.h1`
	font-size: ${({ fontSize }) => fontSize};
	font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'bold')};
	margin-top: ${({ marginTop }) => marginTop};
	color: ${({ Color, theme }) => (Color ? Color : theme.text)};
	cursor: ${({ ishovering }) => ishovering && 'pointer'};
	position: ${({ Position }) => Position};
	text-align: start;

	@media (max-width: ${breakpoint.mediaQuerySm}) {
		font-size: 22px;
	}

	@media (max-width: ${breakpoint.mediaQueryXSm}) {
		font-size: 20px;
	}

	:hover {
		color: ${({ ishovering, theme }) => ishovering && theme.green};
	}
`;

export const Subtitle = styled.h3`
	font-size: ${fontSize.text15};
	color: ${({ theme }) => theme.subtitleCardColor};
	padding-bottom: ${({ pb }) => pb || '0'};

	@media (max-width: ${breakpoint.mediaQueryXSm}) {
		font-size: 16px;
	}
`;

export const Description = styled.p`
	color: ${({ theme }) => theme.text};
	${({ clipText }) =>
		clipText &&
		css`
			display: -webkit-box;
			max-width: 100%;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
		`}
`;

export const Text = styled.p`
	font-size: ${({ font }) => font || fontSize.text12};
	color: ${({ theme, textColor }) => (textColor ? textColor : theme.text)};
	margin-left: ${({ margin }) => margin};
	font-weight: ${({ fontWeight }) => fontWeight};
	width: ${({ Width }) => Width};
	padding-bottom: ${({ pb }) => pb};
	padding: ${({ padding }) => padding};
	display: inline-block;
	text-align: ${({ textAlign }) => textAlign};
	font-family:
		Segoe UI,
		sans-serif;

	@media (max-width: ${breakpoint.mediaQuerySm}) {
		font-size: ${({ font }) => font || '1rem'};
	}

	@media (max-width: ${breakpoint.mediaQueryXSm}) {
		font-size: ${({ font }) => font || '0.75rem'};
	}
`;

export const CardDate = styled.p`
	color: ${({ theme }) => theme.darkGray};
	font-size: 16px;

	@media (max-width: ${breakpoint.mediaQueryXSm}) {
		font-size: 16px;
	}
`;

export const FlexText = styled(Text)`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

export const TechnologyContainer = styled.div`
	width: ${({ width }) => width || 'fit-content'};
	display: flex;
	align-items: center;
	gap: 0.5rem;
	position: relative;
	flex-wrap: wrap;
`;

export const ExternalContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	gap: 0.5rem;
	width: ${({ $width }) => $width};
	opacity: ${({ isAssigned }) => isAssigned && '50%'};
`;

export const scrollBarStyle = css`
	&::-webkit-scrollbar {
		background: transparent;
		width: 3px;
	}

	&::-webkit-scrollbar-thumb {
		background: ${({ theme }) => theme.gray70};
		border-radius: 20px;

		&:hover {
			background: ${({ theme }) => theme.grayModal};
		}
	}
`;

export const Line = styled.hr`
	color: ${({ theme }) => theme.filterLine};
	width: 100%;
	margin: 1.25rem 0;
`;

export const VLine = styled.div`
	height: 100%;
	border-left: ${({ theme }) => theme.filterLine};
	margin: 0 1rem;
`;

export const Border = styled.p`
	font-size: ${fontSize.text18};
	position: absolute;
	left: 0;
	bottom: 0;
	width: 0;
	border-bottom: 0.4rem solid ${({ theme }) => theme.green};
	border-bottom-right-radius: 0.5rem;
	border-bottom-left-radius: 0.5rem;
	animation: border_anim 0.5s linear forwards;

	@keyframes border_anim {
		0% {
			width: 0;
		}
		100% {
			width: 100%;
		}
	}
`;
