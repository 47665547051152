import styled from "styled-components";
import {breakpoint} from "../../globalStyles/GlobalStyles";

export const LoginWrapper = styled.div`
  display: flex;
  height: 100vh;
  background: ${({theme}) => theme.background};

  @media (max-width: ${breakpoint.mediaQueryMd}) {
    flex-direction: column-reverse;
    overflow: hidden;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  margin: auto;
  padding: 0 2rem;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  @media (max-width: ${breakpoint.mediaQueryMd}) {
  }
`;

export const Container = styled.div`
  display: flex;
  width: 50vw;

  @media (max-width: ${breakpoint.mediaQueryMd}) {
    width: 100vw;
    height: 60%;
  }
`;

export const ImageContainer = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
  url("https://images.unsplash.com/photo-1549692520-acc6669e2f0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoint.mediaQueryMd}) {
    width: 100vw;
    height: 40%;
  }
`;

export const LoginButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.13rem solid ${({theme}) => theme.grayBorder};
  border-radius: 0.35rem;
  width: 100%;
  padding: 0.5rem 0;
  margin-top: 1rem;
`;
