import axios from "../http";
import FormatUtils from "../../utils/FormatUtils";

const EmployeesService = {
    getEmployees: async (filter, subfilter, sortFilter, skillsFilter, text) => {
        let url = FormatUtils.formatUrlEmployees(
            filter,
            subfilter,
            sortFilter,
            skillsFilter,
            text
        );
        
        const {data} = await axios.get(url);
        return data;
    },

    getEmployee: async (id) => {
        let url = FormatUtils.formatUrlEmployee(id);
        const {data} = await axios.get(url);
        return data;
    },
    editCurrentProject: async (project) => {
        const url = `/employees/edit/${project.user_id}`;
        const {data} = await axios.put(url, project);

        return data;
    },

    getSortedEmployees: async (id, sortFilter, sortDropdown, text) => {
        let url = FormatUtils.formatUrlSortedEmployees(
            id,
            sortFilter,
            sortDropdown,
            text
        );

        const {data} = await axios.get(url);

        return data;
    },

    getAllSkills: async () => {
        const url = "/skills/list";

        const {data} = await axios.get(url);

        return data;
    },

    addEmployeeSkill: async (skill) => {
        const url = `/employees/${skill.id}/skills`;

        const {data} = await axios.put(url, skill);

        return data;
    },

    unassignFromLead: async (lead) => {
        const url = `/employees/remove/${lead.userId}`;

        const {data} = await axios.put(url, lead);

        return data;
    },

    editLead: async (lead) => {
        const url = `/employees/editLead/${lead.userId}`;

        const {data} = await axios.put(url, lead);

        return data;
    },

    deleteTechnology: async (skill) => {
        const url = `/employees/${skill.id}/skills`;
        return await axios.delete(url, {data: skill});
    },

    updateEmployeeSkillsForProject: async (employeeId, data) => {
        const url = `/employees/addProjectSkill/${employeeId}`;
        return await axios.put(url, data);
    },

    hideEmployeeFromRP: async (employeeId, data) => {
        const url = `/employees/hide/${employeeId}`;
        return await axios.put(url, data);
    },

    availableEmployees: async () => {
        const url = "/employees/dashboard/availableEmps";
        const {data} = await axios.get(url);
        return data;
    },

    getEmployeeImage: async () => {
        const url = `/employees/navbar/image`;
        const {data} = await axios.get(url);
        return data;
    },
};

export default EmployeesService;
