import {useSelector} from "react-redux";
import {Toaster} from "react-hot-toast";
import {ThemeProvider} from "styled-components";
import Navbar from "../../container/navbar/Navbar";
import Sidebar from "../../container/sidebar/Sidebar";
import {GlobalStyles} from "../../globalStyles/GlobalStyles";
import {darkTheme, lightTheme} from "../../globalStyles/mode/Themes";
import {useDarkMode} from "../../globalStyles/mode/UseDarkMode";
import {Outlet} from "react-router-dom";
import {useState} from "react";
import Login from "../../pages/login/Login";
import PageContainer from "../../globalStyles/PageContainer";

function RootLayout() {
    const [theme, themeToggler] = useDarkMode();
    const themeMode = theme === "light" ? lightTheme : darkTheme;
    const token = useSelector((state) => state.auth.isLoggedIn)
    const [isSidebarActive, setSidebarActive] = useState(false)

    return (
        <ThemeProvider theme={themeMode}>
            <GlobalStyles/>
            <Toaster position="top-center" reverseOrder={false}/>
            {token ? (
                <>
                    <Navbar isSidebarActive={isSidebarActive} toggleSidebar={() => setSidebarActive(!isSidebarActive)}/>
                    <Sidebar isSidebarActive={isSidebarActive} theme={theme} toggleTheme={themeToggler}
                             collapseSidebar={() => setSidebarActive(false)}/>
                    <PageContainer as='main'>
                            <Outlet/>
                    </PageContainer>
                </>
            ) : (
                <Login/>
            )}
        </ThemeProvider>
    );
}

export default RootLayout;
